import {createSlice} from "@reduxjs/toolkit";
import {
  snackNotificationActionsType,
  snackNotificationsState
} from "@/store/snackNotifications/snackNotificationsTypes";


const SNACK_NOTIFICATION_MODULE = 'snackNotifications'

const initialState: snackNotificationsState = {
  notificationsQueue: []
}

const snackNotificationsSlice = createSlice({
  name: SNACK_NOTIFICATION_MODULE,
  initialState,
  reducers: {
    addSnackNotification: (state, {payload}: snackNotificationActionsType['addSnackNotification']) => {
      state.notificationsQueue = [...state.notificationsQueue, payload]
    },
    removeFirstSnackNotification: (state) => {
      state.notificationsQueue = state.notificationsQueue.slice(1)
    }
  }
})

export default snackNotificationsSlice.reducer

export const {
  addSnackNotification,
  removeFirstSnackNotification
} = snackNotificationsSlice.actions