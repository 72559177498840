import {combineReducers} from "@reduxjs/toolkit";

import filterSlice from '@/store/filters/filtersSlice'
import notifications from '@/store/notifications/notificationsSlice'
import modal from '@/store/modal/modalSlice'
import tableSettings from '@/store/table/tableSlice'
import snackNotifications from '@/store/snackNotifications/snackNotificationsSlice'
import appSettingsSlice from '@/store/appSettings/appSettingsSlice'
import userSlice from "@/store/user/userSlice";
import claimsSlice from "@/store/claims/claimsSlice"

export const rootReducer = combineReducers({
  filterSlice,
  notifications,
  modal,
  tableSettings,
  snackNotifications,
  appSettingsSlice,
  userSlice,
  claimsSlice
})