import axios, {AxiosRequestTransformer} from 'axios'
import {proanalyticsApiUrl} from '@/env.config'
import * as _ from "lodash-es"
// @ts-ignore
import qs from "qs"
import {OIDCAuthController} from "@proapteka/oidc-auth-controller";


// Экземпляр axios для всех защищенных запросов в приложении
const axiosInstance = axios.create({
  baseURL: proanalyticsApiUrl,
  timeout: 10 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  transformRequest: [(data) => JSON.stringify(data)],
  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
})

// Контроллер OIDC авторизации
const oAuthControllerInstance = new OIDCAuthController({Axios: axiosInstance})

// Дефолтный трансформер axios
const defaultTransformRequest = (_.get(axios, 'defaults.transformRequest') as AxiosRequestTransformer[])[0]

// Конфиг axios для отправки файлов на бэк
export const fileUploadConfig = {
  headers: {'Content-Type': 'multipart/form-data'},
  transformRequest: defaultTransformRequest
}

export {
  axiosInstance,
  oAuthControllerInstance
}
