import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const APP_SETTINGS_MODULE = 'appSettings'

export type appSettingsStateType = {
  isSaveColumnsSettings: boolean,
  themeMode: 'light' | 'dark'
}

export type appSettingsActionsTypes = {
  setSaveColumnsSettings: {
    payload: boolean
  }
}

const initialState: appSettingsStateType = {
  isSaveColumnsSettings: true,
  themeMode: 'light'
}

const appSettingsSlice = createSlice({
  name: APP_SETTINGS_MODULE,
  initialState,
  reducers: {
    setSaveColumnsSettings: (state, {payload}: appSettingsActionsTypes['setSaveColumnsSettings']) => {
      state.isSaveColumnsSettings = payload
    },
    setThemeMode: (state, {payload: {mode}}: PayloadAction<{mode: appSettingsStateType['themeMode']}>) => {
      state.themeMode = mode
    }
  }
})

export default appSettingsSlice.reducer

export const {
  setSaveColumnsSettings,
  setThemeMode
} = appSettingsSlice.actions