import React from "react";
import {ReactComponent as LogoComponent} from "@/assets/icons/proaptekaLogo.svg"
import "./preloader.styles.css"


export const Preloader = () => {
  return (
      <div className="pulse-loader">
        <LogoComponent style={{width: '64px', height: '64px', fill: '#57A5F1'}} />
      </div>
  )
}