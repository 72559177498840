import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type UserType = {
  userName: string
  email: string
  fullName: string
  id: string
}

type UserStateModel = {
  currentUser: UserType | null
}

export const USER_SLICE_NAME = "user"

const initialState: UserStateModel = {
  currentUser: null
}

const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    setCurrentUser: (state, {payload}: PayloadAction<UserType>) => {
      state.currentUser = payload
    }
  }
})

export default userSlice.reducer
export const {
  setCurrentUser
} = userSlice.actions