  export const proanalyticsApiUrl = import.meta.env.VITE_APP_PROANALYTICS_API_URL
  export const prosystemApiUrl = import.meta.env.VITE_APP_PROSYSTEM_API_URL
  export const directoryApiUrl = import.meta.env.VITE_APP_DIRECTORY_API_URL
  export const reportCreatorProsystemApiUrl = import.meta.env.VITE_APP_REPORT_CREATOR_PROSYSTEM_API
  export const exporterProlearningURL = import.meta.env.VITE_APP_EXPORTER_PROLEARNING
  export const sseHubUrl = import.meta.env.VITE_APP_SSE_HUB
  export const profileUrl = import.meta.env.VITE_APP_PROFILE_URL
  export const passportUrl = import.meta.env.VITE_APP_PASSPORT_URL
  export const appMode = import.meta.env.VITE_APP_MODE
  export const prosystemUrl = import.meta.env.VITE_APP_PROSYSTEM_URL
  export const promarketUrl = import.meta.env.VITE_APP_PROMARKET_URL
  export const prolearningUrl = import.meta.env.VITE_APP_PROLEARNING_URL
  export const f3proUrl = import.meta.env.VITE_APP_F3PRO_URL