import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type ClaimsStateModel = {
  currentClaims: string[] | null
}

export const CLAIMS_SLICE_NAME = 'claims'

const initialState: ClaimsStateModel = {
  currentClaims: null
}

const claimsSlice = createSlice({
  name: CLAIMS_SLICE_NAME,
  initialState,
  reducers: {
    setCurrentClaims: (state, {payload}: PayloadAction<string[]> ) => {
      state.currentClaims = payload
    }
  }
})

export default claimsSlice.reducer
export const {
  setCurrentClaims
} = claimsSlice.actions