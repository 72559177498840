import {FilterModel} from "@proapteka/filters";

export const FILTERS_MODULE = 'FILTERS'


export type FilterStateType = {
  errors: Record<string, string>
  requiredFilters: string[]
  filters: Record<string, FilterModel>
  unappliedFilter: boolean
  firstApplied: boolean
}

export type initFilterStateActionType = {
  scope: string,
  id: string,
  gid: number,
  defaultValue: FilterModel['selected'],
  label: string
}


